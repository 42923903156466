@use '@angular/material' as mat;
@use 'sass:math';

@import 'simple-keyboard/build/css/index.css';

@import "bootstrap/scss/bootstrap";

@import 'variables.scss';

@font-face {
    font-family: 'Archer';
    src: url('/assets/fonts/archer/Archer-Medium.woff2') format('woff2'), url('./assets/fonts/archer/Archer-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter Var';
    src: url('/assets/fonts/intervar/Inter.var.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cabin';
    src: url('/assets/fonts/cabin/Cabin-Regular.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSans';
    src: url('/assets/fonts/bentonsans/benton-sans-regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Interstate';
    src: url('/assets/fonts/interstate/Interstate-Light.woff2') format('woff2'), url('./assets/fonts/interstate/Interstate-Light.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Lemonmilk';
    src:  url('/assets/fonts/lemonmilk/LEMONMILK-Regular.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

$grid-breakpoints: (
  xxxs: 0,
  xxs: 321px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px
);

$container-max-widths: (
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px
);

.darkMode .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #fff !important;
}

.darkMode .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after {
  color: #fff !important;
}

.darkMode .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
  color: #fff !important;
}

.darkMode .mat-pseudo-checkbox:after {
  color: #fff !important;
}

button:focus, button:active:focus, button.active:focus {
  outline: none !important;
  outline-style: none !important;
}

.fol .font-dosis {
  font-family: 'Dosis' !important;
}

.fol .font-permanent-marker {
  font-family: 'Permanent Marker' !important;
}

.form-fol {
  .mat-form-field-flex {
    background-color: #e6e7e8;
    padding: 0 10px;
    border-radius: 4px;
    height: 60px;
    align-items: center;
  }

  .mat-form-field-infix {
    border-top: none;
  }


  .mat-form-field-label-wrapper {
    font-family: 'Dosis' !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background: none !important;
  }
}

.fol {
  .template1.product_description {
    font-size: 1.2em !important;
  }
  
  .template1.product_name {
    font-size: 1.3em !important;
  }

  .pay-kiosk {
    font-size: 1.5rem;
  }

  .kiosk-number {
    font-size: 1.5rem !important;
  }

  .terms-and-conditions {
    font-size: 1.1rem !important;
  }
}

body {
  font-size:14px;
	margin: 0px;
  color: black;
  overscroll-behavior-y: none;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased !important;
}

a:hover {
  text-decoration: none;
}

body.fol {
    font-size: 16px;
}

.invisible-checkbox {
  &.mat-mdc-list-option .mdc-checkbox {
    display: none !important;
  }
  
  &.mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__end {
    width: 0;
  }

  > * > .mdc-radio {
    display: none !important;
    width: 0;
  }
}

.first-part {
  padding-top: env(safe-area-inset-top) !important;
  //padding-top: 45px !important;
}

.last-part {
  padding-bottom: env(safe-area-inset-bottom) !important;
  //padding-bottom: 40px !important;
}

body::-webkit-scrollbar {
  display: none;
}

html {
   -ms-overflow-style: none;
}

.intl-tel-input {
  width: 100%;
}

.telInput {
  width: 100%;
}

.transparent {
  background-color: transparent !important;
}

.nobounce {
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.noscroll {
  overflow: hidden;
}

.clickable {
  cursor: pointer;
}

a {
  color: mat.get-color-from-palette($primary);
  cursor: pointer;
}

.advisory {
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
}

.advisory-info {
  background-color: mat.get-color-from-palette($primary);
}

.advisory-warning {
  background-color: mat.get-color-from-palette($tertiary);
}

.basket-content {
    height: 100%;
    overflow-x:hidden;
    overflow-y: auto;
}

.basket-content::-webkit-scrollbar {
  display: none;
}

.mat-list-item-content {
  padding: 0px !important;
}

.my-groups {
  > span > span.mdc-list-item__primary-text {
    display: flex !important;
    align-items: center;
  }
}

.white {
  .mat-list-base .mat-list-item {
    color: white !important;
  }
}

.privacy_statement {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.8em;
}

.titlebar {
  background-color: mat.get-color-from-palette($primary);
  font-weight: bolder;
  color: white;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.seperator {
  width: 90vw;
  border: 0.5px solid lightgray;
}

.subtitle {
  font-weight: bolder;
  width: 100%;
  border-bottom: 1px solid lightgray;
  margin-bottom: 5px;
}

.bold {
  font-weight: bolder;
}

.no_selection {
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
} 

.align-right {
  text-align: right;
}

.main-card {
    width: 100%;
  }

.selected-product {
  position:fixed;
  width: 100vw;
  padding: 0px;
  z-index: 999;
  top: 0;
  left: 0;
}

.kiosk-details {
  color: white;
  background-color: white;
  position:fixed;
  width: 100vw;
  height: 100%;
  padding: 0px;
  z-index: 999;
  top: 0;
  left: 0;
}

.mat-button-focus-overlay, .mat-button-ripple {
  z-index: -1;
}

.mat-button-focus-overlay {
  display: none;
}

.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    opacity: 0.5;
}

.default_bg_color {
  background-color: $bgColor;
}

.main-button > span.mdc-button__label {
  width: 100% !important;
}

.darkMode .pos-settings-tab button.mat-mdc-tab-header-pagination {
	color: white !important;
}

.buzzer {
  & .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
    transform: translateY(-106%) !important;
  }

  & .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-106%) !important;
  }

  & .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;
  }

  & .mat-mdc-form-field-focus-overlay {
    background: none !important;
  }

  & input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type=number] {
    -moz-appearance: textfield;
  }
}

@media(max-width: 767px) {
  
  .selected-product {
    height: 100%;
  }

}

@media(min-width: 768px) and (max-width: 1023px) {

  .subtitle {
    font-size: 1.3em;
    margin-bottom: 10px;
  }

  .main-card {
    width: 650px;
  }

  .selected-product {
    position:fixed;
    width: 85vw;
    height: 85vh;
    margin-left: 7vw;
    margin-top: 7vh;
    border-radius: 0px;
  }
  
}

@media(min-width: 1024px) and (max-width: 1199px) {

  .subtitle {
    font-size: 1.3em;
    margin-bottom: 10px;
  }

  .main-card {
    width: 850px;
  }

  .selected-product {
    position:fixed;
    width: 85vw;
    height: 85vh;
    margin-left: 7vw;
    margin-top: 7vh;
    border-radius: 0px;
  }

}

@media(min-width: 1200px) {

  .subtitle {
    font-size: 1.3em;
    margin-bottom: 10px;
  }

  .main-card {
    width: 850px;
  }

  .selected-product {
    position:fixed;
    width: 50vw;
    height: 85vh;
    margin-left: 25vw;
    margin-top: 7vh;
    border-radius: 0px;
  }

}


h2 {
  color: mat.get-color-from-palette($primary);
}

/*mat-card-title {
    color: mat.get-color-from-palette($primary);
}*/

a:hover {
  text-decoration: none;
}

.full-width-dialog {
  width: 100vw !important;
  max-width: 100vw !important;
}

.primary {
    color: mat.get-color-from-palette($primary) !important;
}

.secondary {
    color: mat.get-color-from-palette($secondary) !important;
}

.secondary_low {
    color: mat.get-color-from-palette($secondary);
}

.bg-primary {
    background-color: mat.get-color-from-palette($primary) !important;
}

.bg-secondary {
    background-color: mat.get-color-from-palette($secondary) !important;
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary { 
    font-family: $font_family;
    background-color: mat.get-color-from-palette($primary) !important;
    opacity: 0.9;
    color: white;
}

.mat-flat-button.mat-secondary, .mat-raised-button.mat-secondary, .mat-fab.mat-secondary, .mat-mini-fab.mat-secondary {
    font-family: $font_family;
    background-color: mat.get-color-from-palette($secondary) !important;
    color: white !important;
    font-weight: bolder !important;
}

.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    font-family: $font_family;
    color: grey;
}

.mat-mdc-card {
    font-family: $font_family;
}

.main-card.mat-mdc-card, .authentication-card.mat-mdc-card {
  background-color: rgba(255,255,255,0.6) !important;
  box-shadow: none !important;
  border: 0px;
  padding: 16px;
}


body {
  font-family: $font_family;
}

.link {
    text-decoration: underline !important;
    color: black !important;
}

.icon-img {
    filter: brightness(0) saturate(100%) invert(74%) sepia(93%) saturate(0%) hue-rotate(98deg) brightness(100%) contrast(103%);
}

.input-field {
  border: none;
  background-color: white !important;
  width: 100%;
  border-radius: 5px;
  padding: 5px 15px 5px 15px !important;
}

.telInput {
    background-color: transparent;
    border: 0px;
}

.bg-secondary {
  background-color: mat.get-color-from-palette($secondary);
}

.footer {
  background-color: mat.get-color-from-palette($secondary);
  color: white;
  opacity: 1;
  width: 100%;
  z-index: 500;
  bottom: 0;
}

.footer-available {
  background-color: mat.get-color-from-palette($secondary);
  color: $font-color-primary;
}

.main-bgColor {
  background-color: $bgColor !important;
}

.promotion {
  background-color: mat.get-color-from-palette($primary);
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
}

.notification {
  background-color: mat.get-color-from-palette($primary);
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
}

.marker {
  border-left: 4px solid mat.get-color-from-palette($secondary);
}

.voucher-button {
  color: $font-color-primary;
  background-color: mat.get-color-from-palette($secondary);
}

.loyalty-card {
    box-shadow: 10px 10px 43px -18px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    height: 300px;
    width: 100%;
    cursor: pointer;
    position: relative;
    margin: auto;
    max-width: 450px;
}

.loyalty-card.flipped {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
}

.loyalty-card-background-2 {
    border-radius: 10px;
}

.loyalty-card-background {
    background: -moz-linear-gradient(bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border-radius: 10px;
}

.loyalty-card-background-hard {
    background: -moz-linear-gradient(bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border-radius: 10px;
}

.signage_product_price {
  font-weight: bolder;
  background-color: mat.get-color-from-palette($secondary);
  color:white; 
  border-radius: 5px; 
  width: fit-content;
  padding-top:1px;
  padding-bottom:1px;
  padding-left:5px;
  padding-right:5px;
}

.categorybar {
  
  transition: all .2s ease-in-out; 
  width: 100%;
  z-index: 996;

            overflow-x: auto;
            .list-thumbnail{
                background-color: mat.get-color-from-palette($primary);
                white-space: nowrap;
                .img-thumb{
                    display: inline-block;
                    min-width: 100px;
                    height: 100%;
                    background-color: mat.get-color-from-palette($primary);
                }
            }
}

.color_primary {
  color: mat.get-color-from-palette($primary);
}

.color_secondary {
  color: mat.get-color-from-palette($secondary);
}

.color_tertiary {
  color: mat.get-color-from-palette($tertiary);
}

.bg_primary {
  background-color: mat.get-color-from-palette($primary);
}

.bg_secondary {
  background-color: mat.get-color-from-palette($secondary);
}

.bg_tertiary {
  background-color: mat.get-color-from-palette($secondary);
}

.font_primary {
  color: $font-color-primary;
}

.font_secondary {
  color: $font-color-secondary;
}

.full-width {
   width: 100%;
}

/* BOOTSTRAP SWITCHES */
@mixin switch($res: 'sm') {
    $index: 1rem;
    $mainVal: 1rem;
  
    @if $res == 'md' {
        $index: 2rem;
        $mainVal: 1.5rem;
    } @else if $res == 'lg' {
        $index: 3rem;
        $mainVal: 2rem;
    } @else if $res == 'xl' {
        $index: 4rem;
        $mainVal: 2.5rem;
    }

    margin-bottom: math.div($index, 2); /* JUST FOR STYLING PURPOSE */

    .form-check-input {
        height: $mainVal;
        width: calc(#{$index} + 0.75rem);
        border-radius: $mainVal * 2;
    }
}

.form-check-input {
  clear: left;
}

// YOU CAN PUT ALL RESOLUTION HERE
// sm - DEFAULT, md, lg, xl

.form-switch.form-switch-sm {
    @include switch();
}

.form-switch.form-switch-md {
    @include switch('md');
}

.form-switch.form-switch-lg {
    @include switch('lg');
}

.form-switch.form-switch-xl {
    @include switch('xl');
}

/* BOOTSTRAP COLOR UPDATES */
.form-check-input:checked {
    background-color: mat.get-color-from-palette($secondary);
    border-color: mat.get-color-from-palette($secondary);
}


/* NG-BOOTSTRAP COLOR UPDATES */
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: mat.get-color-from-palette($primary);
}

.nav {
    --bs-nav-link-color: mat.get-color-from-palette($primary);
    --bs-nav-link-hover-color: mat.get-color-from-palette($primary);
}

.accordion-button:not(.collapsed) {
    color: mat.get-color-from-palette($primary);
    background-color: mat.get-color-from-palette($primary,100);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 mat.get-color-from-palette($primary);
}

.accordion-button:focus {
    z-index: 3;
    border-color: mat.get-color-from-palette($primary,100);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.counter-fab {
   color: mat.get-color-from-palette($secondary) !important;
}

.marked-product {
  border-left: 6px solid mat.get-color-from-palette($secondary);
  min-height: 60px;
}

.marked-product-text {
  font-weight: bolder;
  color: mat.get-color-from-palette($secondary);
}

/* -------------------------- */

/* TEMPLATE 1 */

.template1.basket {
  position: fixed;
  top: 200px;
  right: 15px;
  width: 30vw;
}

.template1.basket-main-content {
    background-color: $bgColor !important;
    overflow-y: auto;
    border-radius: 5px;
    max-height: calc(100vh - 350px);
}

@media(min-width: 992px) {
  .template1.basket {
    top: 260px;
  }
}

/* TEMPLATE 2 */

.template2.basket {
  position: fixed;
  top: 200px;
  right: 15px;
  width: 400px;
}

.template2.basket-main-content {
    background-color: $bgColor !important;
    overflow-y: auto;
    border: 1px solid lightgray;
    border-radius: 5px;
    max-height: calc(100vh - 350px);
}

@media(min-width: 992px) {
  .template2.basket {
    top: 260px;
  }
}

.navigation-dialog-panel-class .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px !important;
    background-color: transparent !important;
}

/* CATEGORY */

.template1.category_title {
  background-color: mat.get-color-from-palette($primary);
  color: $font-color-secondary;
  font-size: 18px;
  font-weight: bolder;  
  width: 100%;
  position: relative;
  top: -1px;
}

.template2.product_price {
  font-weight: bolder;
  background-color: mat.get-color-from-palette($secondary);
  color:white; 
  border-radius: 5px; 
  width: fit-content;
}

.template2.product_price_signage {
  font-weight: bolder;
  background-color: mat.get-color-from-palette($secondary);
  color:white; 
  font-size:30px;
  border-radius: 5px; 
  width: fit-content;
}

.template_kiosk.product_price {
  font-weight: bolder;
  background-color: mat.get-color-from-palette($secondary);
  color:white; 
  border-radius: 5px; 
  width: fit-content;
}

.template_kiosk.product_price_signage {
  font-weight: bolder;
  background-color: mat.get-color-from-palette($secondary);
  color:white; 
  font-size:30px;
  border-radius: 5px; 
  width: fit-content;
}

@media (min-width: 992px) and (max-width: 1439px) { 


  .marked-product {
    border-left: 5px solid mat.get-color-from-palette($secondary);
    min-height: 70px;
  }

  .product-col:not(:focus):hover {
    background-color: $bgColor;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1;
    transform: scale(1.06);
  }

} 

@media (min-width: 1440px) {  

  .marked-product {
    border-left: 5px solid mat.get-color-from-palette($secondary);
    min-height: 110px;
  }

  .product-col:not(:focus):hover {
      background-color: $bgColor;
      border-radius: 5px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      z-index: 1;
      transform: scale(1.16);
  }
    
}

.invisible-scroll {
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.invisible-scroll::-webkit-scrollbar {
  display: none;
}

.pos {
  .mdc-menu-surface.mat-mdc-select-panel {
    overflow-y: scroll;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
  
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

gridster.lightMode {
  > .gridster-column {
  border-left: 1px solid #8a8a8a !important;
  border-right: 1px solid #8a8a8a !important;
  }
}

gridster.lightMode {
  > .gridster-row {
  border-top: 1px solid #8a8a8a !important;
  border-bottom: 1px solid #8a8a8a !important;
  }
}

.backdropBackground {
  background-color: rgba(0,0,0, 0.75);
}
