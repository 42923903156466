@use '@angular/material' as mat;

$font_family: 'Permanent Marker', sans-serif;
$second_font_family: 'Dosis', sans-serif;

$md-mcgpalette0: (
    50 : #fdeae8,
    100 : #fbcbc5,
    200 : #f8a89f,
    300 : #f58579,
    400 : #f26b5c,
    500 : #f0513f,
    600 : #ee4a39,
    700 : #ec4031,
    800 : #e93729,
    900 : #e5271b,
    A100 : #ffffff,
    A200 : #ffe7e5,
    A400 : #ffb6b3,
    A700 : #ff9e99,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-secondary: (
    50 : #fffae5,
    100 : #fef2bf,
    200 : #fee994,
    300 : #fee069,
    400 : #fdda49,
    500 : #fdd329,
    600 : #fdce24,
    700 : #fcc81f,
    800 : #fcc219,
    900 : #fcb70f,
    A100 : #ffffff,
    A200 : #fffcf4,
    A400 : #ffebc1,
    A700 : #ffe3a7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-mcgpalette2: (
    50 : #f5e3e4,
    100 : #e6b9bb,
    200 : #d68a8e,
    300 : #c65b60,
    400 : #b9383e,
    500 : #ad151c,
    600 : #a61219,
    700 : #9c0f14,
    800 : #930c11,
    900 : #830609,
    A100 : #ffb1b2,
    A200 : #ff7e80,
    A400 : #ff4b4e,
    A700 : #ff3135,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$primary: mat.define-palette($md-mcgpalette0);
$secondary: mat.define-palette($md-mcgpalette0);
$tertiary: mat.define-palette($md-secondary);

$accent: mat.define-palette(mat.$orange-palette,200);
$bgColor: white;

$theme: mat.define-light-theme(
    (
        color: (
            primary: $secondary, 
            accent: $accent
        )
    )
);

@include mat.all-component-themes($theme);

$font-color-secondary: white;
$font-color-primary: white;

/* DARK MODE */
$angular-warn: mat.define-palette(mat.$red-palette);

$angular-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $angular-warn,
    )
  )
);

.darkMode {
    @include mat.all-component-colors($angular-dark-theme);
}

body {
    font-size: 19px !important;
}